import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from 'apollo-boost';
import fetch from 'node-fetch';

const httpLinkGrav = new HttpLink({ uri: process.env.GATSBY_API_URL+`/gravql/key:${process.env.GATSBY_GRAPHQL_ACCESS_KEY}`, fetch: fetch });
const httpLinkGraphQlDb = new HttpLink({ uri: process.env.GATSBY_API_URL+`/v1/key:${process.env.GATSBY_GRAPHQL_ACCESS_KEY}`, fetch: fetch });

const authLink = new ApolloLink((operation, forward) => {
    // Retrieve the authorization token from local storage.
    // const token = localStorage.getItem('auth_token');
    const token = process.env.GATSBY_GRAPHQL_ACCESS_KEY;

    // Use the setContext method to set the HTTP headers.
    operation.setContext({
        headers: {
            authorization: token ? `Bearer ${token}` : ''
        }
    });

    // Call the next link in the middleware chain.
    return forward(operation);
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
}

export const grav = new ApolloClient({
    link: authLink.concat(httpLinkGrav), // Chain it with grav
    cache: new InMemoryCache(),
    fetchOptions: {
        mode: 'no-cors'
    },
});

export const db = new ApolloClient({
    link: authLink.concat(httpLinkGraphQlDb), // Chain it with graphql db
    cache: new InMemoryCache(),
    fetchOptions: {
        mode: 'no-cors'
    }
});
